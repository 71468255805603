import React, { forwardRef, memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { useWidgetReachGoal } from 'common/components/metrics/utils';
import { RCM_BLOCK_TYPE, useBlockInitAtd } from 'common/hooks/useRcm';
import { getWidgetTitle } from 'common/redux/commonData/widgets/consensusWidget/utils';
import {
  selectDomainConfig,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import { CURRENCY_CHAR_CODE } from 'config/constants/finance';
import { domainGenerator } from 'utils/urlGenerator';

import { TopNewsWidget } from './components/TopNewsWidget';
import { PROJECT_ALIASES_IN_RELATIVE } from './constants';
import { useFinanceNewsWidget } from './hooks/useFinanceNewsWidget';
import { useRecommendedNewsWidget } from './hooks/useRecommendedNewsWidget';
import { useTopMainNewsWidget } from './hooks/useTopMainNewsWidget';
import { useTopProjectNewsWidget } from './hooks/useTopProjectNewsWidget';

export const TOP_NEWS_WIDGET_HEIGHT_DEFAULT = 540;
export const RCM_NEWS_WIDGET_HEIGHT_DEFAULT = 540;

type TopMainNewsWidgetPropsType = {
  excludeIds?: CardData['id'][];
  level?: number;
};

/**
 * Виджет топа новостей главной.
 * @param props.excludeIds - исключаемые из отображения id.
 */
export const TopMainNewsWidget = memo(
  ({ excludeIds = [], level }: TopMainNewsWidgetPropsType) => {
    const { clusters, isError, fetchData } = useTopMainNewsWidget(excludeIds);

    const { reachGoalNewsClickCallback, widgetRef } = useWidgetReachGoal(level);

    return (
      <TopNewsWidget
        clusters={clusters}
        isError={isError}
        onRefresh={fetchData}
        title="Главное сейчас"
        url="https://www.rambler.ru/"
        top100Value="main_now"
        onClick={reachGoalNewsClickCallback}
        ref={widgetRef}
      />
    );
  },
);

/**
 * Виджет топа новостей проекта.
 * @param ref – реф для контейнера виджета, чтобы получать высоту выше;
 * @param level – номер этажа на котором отображается виджет.
 */
export const TopProjectNewsWidget = memo(
  forwardRef<HTMLDivElement, { level?: number }>(({ level }, ref) => {
    const domainConfig = useSelector(selectDomainConfig, shallowEqual);
    const projectId = useSelector(
      selectProjectId,
    ) as keyof typeof PROJECT_ALIASES_IN_RELATIVE;

    const { clusters, isError, fetchData } = useTopProjectNewsWidget(projectId);

    const { reachGoalNewsClickCallback, widgetRef } = useWidgetReachGoal(level);

    const url = `${domainGenerator(domainConfig, projectId)}`;

    return (
      <div ref={widgetRef}>
        <TopNewsWidget
          clusters={clusters}
          title={PROJECT_ALIASES_IN_RELATIVE[projectId]}
          url={url}
          isError={isError}
          onRefresh={fetchData}
          top100Value="top_news"
          onClick={reachGoalNewsClickCallback}
          ref={ref}
        />
      </div>
    );
  }),
);

type RCMNewsWidgetPropsType = {
  clusterID: ClusterData['id'];
  level: number;
};

/**
 * Виджет рекоммендованных новостей.
 * @param clusterID - id кластера, для которого загружаются новости рекомендаций.
 */
export const RCMNewsWidget = memo(
  ({ clusterID, level }: RCMNewsWidgetPropsType) => {
    const { clusters, isError, fetchData } =
      useRecommendedNewsWidget(clusterID);

    useBlockInitAtd(RCM_BLOCK_TYPE.topNowDesktop, clusterID);

    const { reachGoalNewsClickCallback, widgetRef } = useWidgetReachGoal(level);

    return (
      <TopNewsWidget
        clusters={clusters}
        title="Новости для вас"
        isError={isError}
        onRefresh={fetchData}
        top100Value="rcm_news"
        rcmKey={RCM_BLOCK_TYPE.topNowDesktop}
        contextItemId={clusterID}
        onClick={reachGoalNewsClickCallback}
        ref={widgetRef}
      />
    );
  },
);

type TopFinanceNewsWidgetPropsType = {
  currency: CURRENCY_CHAR_CODE;
  newsCount?: number;
};

/**
 * Виджет на страницы финансах.
 * @param ref – реф для контейнера виджета, чтобы получать высоту выше;
 */
export const TopFinanceNewsWidget = memo(
  forwardRef<HTMLDivElement, TopFinanceNewsWidgetPropsType>(
    ({ currency, newsCount }, ref) => {
      const domainConfig = useSelector(selectDomainConfig, shallowEqual);
      const projectId = useSelector(selectProjectId);

      const { clusters, isError, fetchData } = useFinanceNewsWidget({
        currency,
      });

      const clustersByCount = newsCount
        ? clusters.slice(0, newsCount)
        : clusters;

      const { title, path } = getWidgetTitle(currency);

      const url = `${domainGenerator(domainConfig, projectId)}${path}`;

      return (
        <TopNewsWidget
          clusters={clustersByCount}
          title={title}
          url={url}
          isError={isError}
          onRefresh={fetchData}
          top100Value="more_news"
          withCaptionTitle
          initialHeight
          ref={ref}
          isVisibleWithoutJS
        />
      );
    },
  ),
);
